<template>
  <v-container>
    <v-row class="d-flex align-start">
      <v-col lg="9" class="my-5 pr-5">
        <h1 class="indigo--text text--lighten-1 text-center">
          UN AÑO CON EMMET FOX
        </h1>
        <div class="d-flex justify-center mb-5">
          <v-img src="../../public/emmet_fox.png" max-width="40%"></v-img>
        </div>
        <p class="mb-5 text-center">{{ efHoy.dia }} | {{ efHoy.titulo }}</p>

        <p class="text-justify" v-html="efHoy.cuerpo"></p>
      </v-col>
      <v-col lg="3" class="pl-2"><PanelDerecho /></v-col>
    </v-row>
  </v-container>
</template>
<script>

import PanelDerecho from "../components/Inicio/PanelDerecho";
export default {
  name: "UnAnoConEmmetFox",
  components: {
    PanelDerecho,
  },
  computed: {
    efHoy() {
      const emmetFoxHoy = this.$route.params.id;
      return this[emmetFoxHoy];
    },
  },
  data() {
    return {
      septiembre16: {
        dia: "16 DE SEPTIEMBRE",
        titulo: "PARTE INTEGRAL DE LA CURACIÓN",
        cuerpo: "<p>El cuerpo no puede resistir la curación. No puede aún tratar de resistirla. No puede, por así decirlo, aún querer resistirla, porque eso no es inteligente. Es importante entender este hecho cuando ores por tu propia curación o la de otro, porque de otro modo, sin realizar esto, tú estás apto para entrar en una pugna mental con el cuerpo, para obligarlo a cambiar y por supuesto tal proceder es completamente inútil y también cansador. Es tu sincera creencia acerca de tu cuerpo, la que tiene que ser cambiada –cambiada desde la falsa creencia a la Verdad-.</p><p><i>Entonces… tu salvación se dejará ver pronto, e irá tu justicia delante de ti…</i> (Isaías 58:8).</p>"
      },
      septiembre17: {
        dia: "17 DE SEPTIEMBRE",
        titulo: "TESTIMONIO",
        cuerpo: "<p>Yo sé cuando la gente pasa por mi fila, quién es el que quiere consejo. Ellos tienen que contarme su historia, en un minuto tengo que obtener su historia exacta y ellos tienen que ir al punto. Por regla general, ellos no lo hacen. Usualmente, en lugar dicen “no voy a apoderarme de su tiempo, yo sé que un hombre público no tiene mucho tiempo. Pensé que si sólo pudiese hablar con usted y decirle lo que hay en mi mente…” ¿Pero por qué no lo hacen? Porque están preocupados, por lo tanto, ellos no pueden decir qué hay en sus mentes. Si puedo parecer impaciente, perdóneme. Yo sé que si tú tienes un problema, será difícil para ti ir al punto. Si pudieras no sería mucho problema. Pero esto puedo decirte: en mi trabajo he tenido a toda clase de personas viniendo a mí por consejo y ayuda, he estado haciendo este trabajo por algunos años, y no hay ninguna clase de persona que no me haya dicho todo acerca de sí mismo. Nunca he encontrado un caso que no pudiera ser cambiado y puesto correcto si el paciente coopera.</p><p><i>Generación a generación celebrará tus obras, y anunciará tus poderosos hechos.</i> (Salmo 145:4).</p>"
      },
      septiembre18: {
        dia: "18 DE SEPTIEMBRE",
        titulo: "EL ARTE DE LA CONCENTRACIÓN",
        cuerpo: "<p>El único asunto que importa, es obtener un entendimiento de la Verdad, desarrollando nuestras almas, debemos tener algún conocimiento del poder de la concentración. Si puedes aprender y practicar la concentración correcta, no hay cosa buena en el Universo que no puedas lograr. Concentración, significa literalmente “llevando a un centro común”. Hasta que puedas poner tu atención donde la quieras, no te has convertido en amo de ti mismo. Nunca serás feliz, hasta que puedas determinar acerca de qué vas a pensar por la siguiente hora. Primero, pon a tu cuerpo confortable y dile que sea bueno hasta que vuelvas por él. La concentración no tiene nada que hacer con los músculos, no tiene nada que hacer con los vasos sanguíneos. La concentración significa control del pensamiento. Es puramente una cosa mental y si estás concentrado correctamente, encontrarás que estás en realidad relajado.</p><p>La gente imagina que la concentración significa retener un pensamiento. La mente humana está construida de tal manera, que es imposible hacer esto. La verdadera concentración es un movimiento de la mente a lo largo de un sendero predeterminado. Por veinte personas que dijeron que habían fallado en la concentración, diecinueve habían tratado de resistir inmóviles mentalmente. Todas las personas tienen buenos poderes de concentración, pero no cuando ellos los quieren. Tú siempre te concentras en lo que estás interesado.</p><p><i>Todo lo puedo en Cristo quien me fortalece.</i> (Filipenses 4:13).</p>"
      },
      septiembre19: {
        dia: "19 DE SEPTIEMBRE",
        titulo: "CONCENTRACIÓN EXITOSA",
        cuerpo: "<p>Yo no doy ejercicios para desarrollar la concentración. Concéntrate en lo que estás haciendo en algún momento, ese es el mejor ejercicio que yo conozco.</p><p>Recuerdo cuando era un niño, alrededor de los doce años, alguien me dio la perfecta cosa admirable, una navaja de bolsillo. Probablemente pesaba un cuarto de libra y tenía una pequeña sierra en ella, un pequeño destornillador, un saca-corchos, una cosa para sacar piedras fuera de los cascos de los caballos y numerosas hojas. La llevé casi un año, pensando lo cómoda que sería para ciertos casos que nunca llegaban, pero nunca perdió su interés. Pude siempre encontrar cuando estaba aburrido, nuevos intereses cuando sacaba la navaja. Si haces de tu búsqueda por Dios, tu navaja, en ese sentido obtendrás tu concentración y éxito.</p><p><i>Así te bendeciré en mi vida… cuando medite en ti en las vigilias de la noche.</i> (Salmo 63:4-6).</p>"
      },
      septiembre20: {
        dia: "20 DE SEPTIEMBRE",
        titulo: "VIVIENDO PARA SU GLORIA",
        cuerpo: "<p>Si pareces estar careciendo de ciertas cualidades necesarias, busca ese aspecto de Dios el cual llenará tu carencia. Si pareces carecer de fortaleza, pide a Dios que te de lo que necesitas. Recuerda que puedes construir cualquier cualidad dentro de tu mentalidad, meditando sobre esa cualidad cada día. Has creado tu ser limitado pensando y puedes destruirlo en cualquier momento mediante pensar en un nuevo molde.</p><p>¿Por qué no cambiamos de día en día, y semana a semana, de gloria a gloria, hasta que nuestros amigos puedan difícilmente reconocernos por el mismo hombre o mujer? ¿Por qué nosotros no marchamos alrededor del mundo viéndonos como dioses y sintiéndolo, curando instantáneamente a todo aquel que venga a nosotros, transformando al pecador y liberando a los cautivos?</p><p><i>El Espíritu de Jehová, el Señor está sobre mí, porque me ungió Yo Soy, me ha enviado a predicar buenas nuevas a los abatidos, a vendar a los quebrantados de corazón, a publicar libertad a los cautivos, y a los presos apertura de la cárcel.</i> (Isaías 61:1).</p>"
      },
      septiembre21: {
        dia: "21 DE SEPTIEMBRE",
        titulo: "LEER Y ESCRIBIR ESPIRITUALMENTE",
        cuerpo: "<p>La Biblia es la más preciada posesión de la raza humana. Nos muestra como vivir. Encuentra a cada uno en su propio nivel y los lleva a Dios. No obstante su valor real se halla en nuestra interpretación de ella.</p><p>Escucha una parábola: “Una isla remota estaba habitada por salvajes altamente inteligentes. Ellos tenían algún arte primitivo y habían hecho excelentes dibujos de animales en las paredes de las cuevas. Una caja fue arrojada a tierra, conteniendo un número de libros secos y en buenas condiciones. Los nativos estaban encantados y escudriñaron esas páginas admirando las extraordinarias formas y diseños hechos por imprenta, totalmente inconscientes de los significados reales detrás de todo eso, inconscientes de la misma existencia de Falstaff, Portia, Hamlet, Huckelberry Finn y de los otros caracteres quienes viven en los libros”.</p><p>Si has estado leyendo la Biblia sin interpretación espiritual, estás exactamente en esa posición.</p><p><i>Erráis, ignorando las escrituras.</i> (Mateo 22:29).</p>"
      },
      septiembre22: {
        dia: "22 DE SEPTIEMBRE",
        titulo: "¿ESTÁS DOBLEMENTE DISPUESTO?",
        cuerpo: "<p>Jesús decía que un hombre doblemente dispuesto era inestable en todos sus caminos y que tal persona no necesita esperar nada de la Gran Ley.</p><p><i>Y si alguno de vosotros tiene falta de sabiduría, pídala a Dios, el cual da a todos abundantemente y sin reproche le será dada. Pero pida con fe, no dudando nada; porque el que duda es semejante a la onda del mar, que es arrastrada por el viento y echada de una parte a otra. No piense, pues quien tal haga, que recibirá cosa alguna del Señor.</i> (Santiago 1:5-7).</p><p>Esto es muy obvio, sentido común, si afirmas una cosa ahora y la contraria en media hora, si meditas provechosamente y luego vas al piso de abajo para hablar de problemas, es enteramente natural que fallarás en influenciar a la gente con la paz que viene de la oración.</p><p>Si entraste a un taxi en Gran Central y le dijiste al conductor que te llevara al Central Park y luego, después de una cuadra, le dijiste que te conduzca al Batery y luego después de un par de cuadras le pediste ir al Central Park de nuevo, difícilmente podrías esperar que el conductor te desembarcara en algún destino. Y no obstante, esto es lo que un buen número de nosotros hacemos en la práctica. Afirmamos ambos, armonía e inarmonía, hasta que la mente subconsciente está completamente confundida y por supuesto nuestras vidas están en confusión.</p><p>Donde nuestras palabras y hechos se refuerzan unos a otros, el efecto es poderoso y el resultado cierto. Donde ellos no están en armonía, se anulan, dejándonos donde empezamos, o más probablemente, peor.</p>"
      },
      septiembre23: {
        dia: "23 DE SEPTIEMBRE",
        titulo: "LA MAREA FLUYE DENTRO Y FUERA",
        cuerpo: "<p>Nosotros no hacemos nuestro progreso espiritual en una uniforme línea recta. La naturaleza humana no trabaja de esa forma. Nadie se mueve hacia arriba en un sendero de continuo progreso al logro de la perfección. Lo que sucede es que nosotros nos movemos regularmente por un tiempo hacia delante, y entonces tenemos un pequeño retroceso. Y luego nos movemos hacia adelante de nuevo y pronto tendremos otro retroceso. Estos retrocesos no son importantes, si el movimiento general de nuestras vidas es hacia adelante.</p><p>La marea fluye dentro y fuera. La ola viene dentro y más adentro, y parece tan vigorosa que nunca parará, hasta alcanzar la marca de marea alta, -pero se detiene- y en realidad vuelve atrás y si uno no conociera mejor, supondría que ese fue el fin de la cuestión. Pero no lo es. La marea vuelve atrás un poco, pero no a su vieja marca y entonces progresa de nuevo y esta vez hace una marca mas alta. Este modo de progresión parece ser en general natural en todas partes.</p><p>No observes las olas individuales, sino mantén tu atención en la marea y todo estará bien.</p><p>Luego les dijo: <i>Id… porque el gozo de Yo Soy es vuestra fuerza.</i> (Jeremías 8:10).</p>"
      },
    };
  },
};
</script>
